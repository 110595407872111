'use client'

import type { ComponentProps, ReactNode } from 'react'

import Image from 'next/image'
import Link from 'next/link'
import { ChevronLeftIcon, IterationCwIcon } from 'lucide-react'

import { Button } from '~/ui/shadcn/ui/button'

const ErrorTemplate = ({
	action = 'Return Home',
	message,
	linkProps,
	type = 'error',
	onClick,
}: {
	action?: string
	message?: string | ReactNode
	linkProps?: Omit<ComponentProps<typeof Link>, 'className'>
	onClick?: ComponentProps<typeof Button>['onClick']
	type?: 'not-found' | 'error' | 'access-denied'
}) => {
	return (
		<div className="m-auto flex h-full max-w-lg flex-col items-center justify-center gap-6 py-12 md:py-24">
			<Image
				priority={true}
				alt="Trestle"
				title="Trestle"
				src="/images/trestle-logo.png"
				height={27}
				width={90}
				className="object-contain"
				quality={100}
			/>
			{type === 'not-found' ? (
				<h2 className="text-2xl font-semibold">
					Page Not Found
				</h2>
			) : type === 'access-denied' ? (
				<h2 className="text-2xl font-semibold">
					Access Denied
				</h2>
			) : (
				<h1 className="text-xl font-semibold">
					An Error Occurred
				</h1>
			)}
			{message ? (
				<div className="text-lg">
					{message}
				</div>
			) : null}
			<div className="space-x-2">
				{onClick && type === 'error' ? (
					<Button
						variant="outline"
						className="min-w-fit space-x-2 rounded-full bg-amber-200"
						onClick={onClick}
					>
						<IterationCwIcon
							className="size-4"
							strokeWidth={2}
						/>
						<span>
							Refresh
						</span>
					</Button>
				) : null}
				<Button
					variant="outline"
					className="min-w-fit rounded-full bg-amber-200"
				>
					<Link
						{...linkProps}
						href={linkProps?.href ?? '/'}
						className="flex items-center gap-2"
					>
						<ChevronLeftIcon
							className="size-4"
							strokeWidth={2}
						/>
						<span>
							{action}
						</span>
					</Link>
				</Button>
			</div>
		</div>
	)
}

export default ErrorTemplate
